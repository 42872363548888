.container {
  border-bottom: 1px solid var(--color-highlight);
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 27px;
  padding-top: 27px;
}

.sokiLogo {
  width: 160px;
}


@media only screen and (max-width: 768px) {
  .sokiLogo {
    width: 140px;
  }
}

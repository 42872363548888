.container {
  border-bottom: 1px solid var(--color-primary);
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;
  padding-top: 24px;
}
.form {
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 400px;
  row-gap: 36px;
  width: 100%;
}

.googleLoginButtonContainer {
  align-self: center;
}

.separator {
  align-items: center;
  column-gap: 16px;
  display: flex;
}

.separatorLine {
  background-color: var(--color-ghost);
  height: 1px;
  width: 100%;
}

.separatorText {
  flex-shrink: 0;
}